import { Injectable } from '@angular/core';

import { combineLatest, filter, first, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as actions from '../store/actions/subscriptions.action';
import { selectLoading, selectLoaded } from '../store/selectors/subscriptions.selectors';
import { selectIsTeamMember } from '../store/selectors/user.selectors';

@Injectable()
export class SubscriptionsResolver {
  resolve(): Observable<boolean> {
    return combineLatest([
      this.store$.pipe(select(selectLoaded)),
      this.store$.pipe(select(selectLoading)),
      this.store$.pipe(select(selectIsTeamMember))
    ]).pipe(
      map(([loaded, loading, isTeamMember]) => {
        if (isTeamMember) return true;

        if (!loaded && !loading) {
          this.store$.dispatch(actions.load());
          return false;
        }
        return loaded;
      }),
      filter(Boolean),
      first()
    ) as Observable<boolean>;
  }

  constructor(private store$: Store) {}
}
