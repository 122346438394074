import { Component, HostBinding } from '@angular/core';
import { Location } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { SeoService } from '@core/services/seo.service';
import { ThemeService } from '@core/services/theme.service';
import { META_DESCRIPTION, META_TITLE } from '@core/constants/meta-tags';
import { Theme } from '@shared/models/theme.model';
import { Lang } from '@shared/models/lang.model';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template: ` <router-outlet />`
})
export class AppComponent {
  @HostBinding('class.dark') get mode() {
    return environment.production ? false : this.themeService.theme() === Theme.dark;
  }

  constructor(
    private location: Location,
    private translateService: TranslateService,
    private seoService: SeoService,
    private themeService: ThemeService
  ) {
    this.setLanguage();
    this.observeLangChanges();
  }

  private setLanguage(): void {
    const lang = localStorage.getItem('lang');
    if (!lang) {
      const path = this.location.path();
      const language = path.includes('/ua') ? Lang.Uk : this.getBrowserLang();
      localStorage.setItem('lang', language);
      this.translateService.use(language);
    } else {
      this.translateService.use(lang);
    }
  }

  private observeLangChanges(): void {
    this.translateService.onLangChange
      .pipe(
        map(({ lang }) => lang as Lang),
        tap(lang => {
          this.seoService.setTitle(META_TITLE[lang]);
          this.seoService.setMetaTags(META_TITLE[lang], META_DESCRIPTION[lang]);
        })
      )
      .subscribe();
  }

  private getBrowserLang() {
    const browserLang = this.translateService.getBrowserLang();
    switch (browserLang) {
      case 'uk':
      case 'ru': {
        return Lang.Uk;
      }
      case 'es': {
        return Lang.Es;
      }
      default: {
        return Lang.En;
      }
    }
  }
}
