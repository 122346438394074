import { Lang } from '@shared/models/lang.model';

export const META_TITLE = {
  [Lang.En]: 'Platform for creating online courses',
  [Lang.Uk]: 'Платформа для створення онлайн курсів',
  [Lang.Es]: 'Plataforma para crear cursos en línea'
};

export const META_DESCRIPTION = {
  [Lang.En]:
    'Quickly and easily create online courses, train students and employees. Share your knowledge with your audience.',
  [Lang.Uk]:
    'Швидко та легко створюйте онлайн курси, навчайте студентів та працівників. Діліться вашими знаннями з вашою аудиторією.',
  [Lang.Es]:
    'Cree cursos en línea, forme a estudiantes y empleados de forma rápida y sencilla. Comparte tus conocimientos con tu audiencia.'
};

export const META_KEYWORDS = {
  [Lang.En]: 'Plekaite, lms, online learning, platform for learning, onboarding, online courses',
  [Lang.Uk]:
    'Плекайте, lms, онлайн навчання, платформа для навчання, створити курс, платформа для онбордингу, дистанційне начання, онлайн курси',
  [Lang.Es]:
    'Plekaite, lms, aprendizaje en línea, plataforma de aprendizaje, crear un curso, plataforma de onboarding, aprendizaje a distancia, cursos en línea'
};
