import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import * as actions from '../actions/user.action';
import * as coreActions from '../actions/core.action';
import * as snackBarActions from '../actions/snack-bar.action';
import { selectUser } from '@core/store/selectors/user.selectors';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class UserEffect {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.load),
      mergeMap(() =>
        this.userService.getUser().pipe(
          map(user => actions.loadSuccess({ user })),
          catchError((error: HttpErrorResponse) => {
            const message = error.error.message;
            return from([
              actions.loadFail({ message: 'Fail user loading' }),
              snackBarActions.errorMessage({ message })
            ]);
          })
        )
      )
    )
  );

  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadSuccess),
      map(({ user }) => coreActions.setLang({ lang: user?.language }))
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.update),
      mergeMap(({ user }) =>
        this.userService.updateUser(user).pipe(
          map(user => actions.updateSuccess({ user })),
          catchError((error: HttpErrorResponse) => {
            const message = error.error.message;
            return from([
              actions.updateFail({ message: 'Fail user loading' }),
              snackBarActions.errorMessage({ message })
            ]);
          })
        )
      )
    )
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateSuccess),
      map(() => snackBarActions.successMessage({ message: 'profile-successfully-updated' }))
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.remove),
      switchMap(({ password }) =>
        this.userService.removeUser(password).pipe(
          map(() => actions.removeSuccess()),
          catchError((error: HttpErrorResponse) => of(actions.removeFail({ error })))
        )
      )
    )
  );

  removeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.removeSuccess),
      map(() => {
        this.authService.logout();
        return snackBarActions.successMessage({ message: 'profile-successfully-deleted' });
      })
    )
  );

  removeFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.removeFail),
      map(({ error }) => snackBarActions.errorMessage({ message: error?.error?.message }))
    )
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePassword),
      mergeMap(({ updatePassword }) =>
        this.userService.updatePassword(updatePassword).pipe(
          map(() => actions.updatePasswordSuccess()),
          catchError((error: HttpErrorResponse) => of(actions.updatePasswordFail({ error })))
        )
      )
    )
  );

  updatePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePasswordSuccess),
      map(() => snackBarActions.successMessage({ message: 'password-successfully-updated' }))
    )
  );

  updatePasswordFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updatePasswordFail),
      map(({ error }) => {
        const message = error.error.message;
        return snackBarActions.errorMessage({ message });
      })
    )
  );

  updateUserLang$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUserLang),
      withLatestFrom(this.store$.pipe(select(selectUser))),
      switchMap(([{ lang }, user]) => {
        const { name, phone, description, gender, photo, languageEmails } = user;
        return this.userService
          .updateUser({
            name,
            phone,
            description,
            gender,
            photo,
            telegramUserName: user.connectedAccounts.telegram.userName,
            language: lang,
            languageEmails
          })
          .pipe(
            switchMap(user => [
              coreActions.setLang({ lang }),
              actions.updateUserLangSuccess({ user })
            ]),
            catchError((error: HttpErrorResponse) => {
              const message = error.error.message;
              return from([
                actions.updateUserLangFail({ error }),
                snackBarActions.errorMessage({ message })
              ]);
            })
          );
      })
    )
  );

  constructor(
    private store$: Store,
    private actions$: Actions,
    private userService: UserService,
    private authService: AuthService
  ) {}
}
