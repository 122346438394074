import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { UserRole } from '../models';
import * as actions from '../store/actions/user.action';

@Injectable()
export class DashboardGuard {
  constructor(
    private userService: UserService,
    private router: Router,
    private store$: Store
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getUser().pipe(
      map(user => {
        if (
          (user && user.role === UserRole.manager) ||
          (user && user.role === UserRole.teamMember)
        ) {
          this.store$.dispatch(actions.loadSuccess({ user }));
          return true;
        }
        this.router.navigate(['']);
        return false;
      }),
      catchError(() => {
        this.router.navigate(['']);
        return of(false);
      })
    );
  }
}
